/**
 * @fileOverview
 * @name FirestoreService.ts
 * @author Taketoshi Aono
 * @license
 */

import { Action } from 'redux';
import { FIRESTORE_MESSAGE_FETCH_LIMIT, staticConfig } from '@w/config';
import { FirebaseAuthentificatableRepository } from '@w/repository/FirebaseAuthRepository';
import { FirestoreConnectable } from '@w/firebase/FirestoreConnection';
import { FirebaseSnapshotFindableQuery } from '@w/query/FirebaseSnapshotQuery';
import { FirestoreDatabaseHolder } from '@w/firebase/FirestoreDatabase';
import { Auth } from '@w/domain/entities/Auth';
import { lockable } from '@w/decorators/lockable';
import { ReduxState } from '@w/domain/entities/State';
import { required } from '@s/assertions';
import { rng } from '@w/util/md5';
import { createRetryHandler } from '@s/io/createRetryHandler';
import { AimWidgetInitialConfig } from '@w/window';
import { uniquifyWelcomeMessageId } from '@w/application/WelcomeMessage';
import { Console } from '@w/util/Console';
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import { LLMMessageFormat, MessageFormat } from '@s/domain/entity/MessageFormat';
import {
  deleteDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

export interface FirestoreDomainService {
  initializeOnce(a: {
    getState(): ReduxState;
    onOperatorChattingStateChange(a: { isStart: boolean }): void;
    onWelcomeMessageTransferRequired(): void;
    onLogin(auth: Auth): void;
    onBatchAddMessage(messages: DisplayableMessageFormat[]): void;
    onAddMessage(message: DisplayableMessageFormat[]): void;
    onModifyMessage(message: DisplayableMessageFormat[]): void;
    onTyping(a: { isTyping: boolean }): void;
    onUpdateEnableCustomerImageUploadState(a: { enable: boolean }): void;
  }): Promise<void>;
  readonly isInitialized: boolean;
  isLoggedIn(): Promise<boolean>;
  fetchPastMessages(a: {
    startAt: number;
    welcomeMessage: DisplayableMessageFormat;
  }): Promise<DisplayableMessageFormat[]>;
  updateTokenIfNecessary(a: { auth: Auth }): Promise<string | null>;
  storeIncidentState(a: {
    state: ReduxState;
    log: Action[];
    customerId: string;
    tenantId: string;
  }): string;
  delete(): Promise<void>;
  updateTyping(a: {
    customerId: string;
    tenantId: string;
    projectId: string;
    isTyping: boolean;
    text: string;
  }): Promise<void>;
  getWelcomeMessageOnClosed(a: {
    state: ReduxState;
    onAddMessage(message: DisplayableMessageFormat[]): void;
    onWelcomeMessageTransferRequired(): void;
  }): Promise<void>;
}

const storeIncidentStoreRetryHandler = createRetryHandler();
export class FirestoreService<FirestoreSchemaType extends LLMMessageFormat | MessageFormat>
  implements FirestoreDomainService
{
  private static isInitialized = false;
  private database: FirestoreDatabaseHolder | null = null;
  private readonly firebaseAuthRepository: FirebaseAuthentificatableRepository;
  private readonly firebaseSnapshotQuery: FirebaseSnapshotFindableQuery<FirestoreSchemaType>;
  private readonly firestoreConnector: FirestoreConnectable;
  private listenerDisposer: (() => void) | null = null;
  private readonly persistency: NonNullable<AimWidgetInitialConfig['persistency']>;
  private typingDisposer: (() => void) | null = null;
  private readonly widgetStartupDatetime = Date.now();
  private converter: (message: FirestoreSchemaType) => DisplayableMessageFormat[];

  public constructor({
    firebaseAuthRepository,
    firebaseSnapshotQuery,
    firestoreConnector,
    persistency,
    converter,
  }: {
    firebaseAuthRepository: FirebaseAuthentificatableRepository;
    firestoreConnector: FirestoreConnectable;
    firebaseSnapshotQuery: FirebaseSnapshotFindableQuery<FirestoreSchemaType>;
    persistency: NonNullable<AimWidgetInitialConfig['persistency']>;
    converter: (message: FirestoreSchemaType) => DisplayableMessageFormat[];
  }) {
    this.firebaseAuthRepository = firebaseAuthRepository;
    this.firebaseSnapshotQuery = firebaseSnapshotQuery;
    this.firestoreConnector = firestoreConnector;
    this.persistency = persistency;
    this.converter = converter;
  }

  public async delete(): Promise<void> {
    await this.firebaseAuthRepository.delete();
    if (this.listenerDisposer) {
      this.listenerDisposer();
      this.listenerDisposer = null;
    }
  }

  @lockable
  public async fetchPastMessages({
    welcomeMessage,
    startAt,
  }: Parameters<FirestoreDomainService['fetchPastMessages']>[0]): ReturnType<
    FirestoreDomainService['fetchPastMessages']
  > {
    if (!this.database) {
      return [welcomeMessage];
    }
    const ret = await this.firebaseSnapshotQuery.find({
      startAt,
      database: this.database,
    });

    const pastMessages = ret.map(elm => this.converter(elm)).flat();

    return !pastMessages.length
      ? [welcomeMessage]
      : pastMessages.length < FIRESTORE_MESSAGE_FETCH_LIMIT
      ? [welcomeMessage, ...pastMessages]
      : pastMessages;
  }

  public async initializeOnce({
    getState,
    onOperatorChattingStateChange,
    onWelcomeMessageTransferRequired,
    onLogin,
    onAddMessage,
    onModifyMessage,
    onBatchAddMessage,
    onTyping,
    onUpdateEnableCustomerImageUploadState,
  }: Parameters<FirestoreDomainService['initializeOnce']>[0]): ReturnType<
    FirestoreDomainService['initializeOnce']
  > {
    Console.info('call initializeOnce');
    if (FirestoreService.isInitialized) {
      return;
    }
    FirestoreService.isInitialized = true;

    const { previewToken } = getState().auth;
    const { env } = getState();

    const { isCreated, auth } = await this.firebaseAuthRepository.login({
      tenantId: getState().env.tenantId,
      previewToken,
    });

    Console.info('firebase login');

    let updatedAuth = auth;
    // NOTE:
    // 新規ユーザのとき(userがないまたは、aim_user_idがないとき)と
    // 既存ユーザのときで、syncWithServer と　onLogin の順番が違うが、
    // なぜか分からない
    // 常に onLogin を後に呼ぶ実装で問題ない気がする
    if (isCreated || !auth.claims.aim_user_id) {
      updatedAuth = await this.firebaseAuthRepository.syncWithServer(
        auth,
        env.projectId,
        env.userData
      );
      Console.info('call syncWithServer 1');
      onLogin(updatedAuth);
    } else {
      onLogin(updatedAuth);
      this.firebaseAuthRepository.syncWithServer(auth, env.projectId, env.userData);
      Console.info('call syncWithServer 2');
    }

    const database = this.getFirestoreDatabase({
      state: getState(),
      auth: updatedAuth,
    });

    const isOperatorChattingStarted = await this.isOperatorChatStarted();
    onOperatorChattingStateChange({ isStart: isOperatorChattingStarted });
    if (isOperatorChattingStarted) {
      this.subscribeOperatorTyping({
        customerId: updatedAuth.claims.aim_user_id,
        tenantId: env.tenantId,
        projectId: env.projectId,
        onUpdate: a => onTyping(a),
      });
    }

    const welcomeMessage = this.converter(
      required(getState().env.welcomeMessage) as FirestoreSchemaType
    );

    let startAt = this.persistency === 'local' ? 0 : this.widgetStartupDatetime;
    if (!isCreated && this.persistency === 'local') {
      const {
        startAt: snapshotStartAt,
        snapshot,
        isCustomerImageUploadEnabled,
      } = await this.firebaseSnapshotQuery.get({
        database,
      });
      startAt = snapshotStartAt;
      if (!snapshot.length) {
        onAddMessage(welcomeMessage);
        onWelcomeMessageTransferRequired();
      } else {
        const messages = snapshot.map(elm => this.converter(elm)).flat();
        if (snapshot.length < FIRESTORE_MESSAGE_FETCH_LIMIT) {
          onBatchAddMessage([...welcomeMessage, ...messages]);
        } else {
          onBatchAddMessage(messages);
        }
      }

      onUpdateEnableCustomerImageUploadState({ enable: isCustomerImageUploadEnabled });
    }
    const isMessage = (a: any) => !!a?.messages?.length;
    this.listenerDisposer = database.listen({
      startAt,
      orders: [['at', 'asc']],
      onChange: change => {
        if (change.type === 'modified') {
          const data = change.doc.data() as FirestoreSchemaType;
          Console.info(data);
          onModifyMessage(this.converter(data));
        }

        if (change.type === 'added') {
          const data = change.doc.data() as FirestoreSchemaType;
          Console.info(data);
          if (isMessage(data)) {
            const messages = this.converter(data);
            for (const message of messages) {
              if (!message.isWelcome) {
                onAddMessage([message]);
              }
            }
          } else {
            switch (data.type) {
              case 'closed':
                onAddMessage(welcomeMessage.map(w => uniquifyWelcomeMessageId(w, data.at)));
                onWelcomeMessageTransferRequired();
                onOperatorChattingStateChange({ isStart: false });
                this.unsubscribeOperatorTyping();
                break;
              case 'assigned':
                onOperatorChattingStateChange({ isStart: true });
                const { env } = getState();
                this.subscribeOperatorTyping({
                  customerId: updatedAuth.claims.aim_user_id,
                  tenantId: env.tenantId,
                  projectId: env.projectId,
                  onUpdate: a => onTyping(a),
                });
                break;
              case 'customerImageUpEnableRequest':
                onUpdateEnableCustomerImageUploadState({ enable: true });
                break;
              case 'customerImageUpDisableRequest':
                onUpdateEnableCustomerImageUploadState({ enable: false });
                break;
            }
          }
        }
      },
    });
  }

  public get isInitialized() {
    return FirestoreService.isInitialized;
  }

  public async isLoggedIn(): Promise<boolean> {
    return this.firebaseAuthRepository.isLoggedIn();
  }

  public storeIncidentState({
    customerId,
    tenantId,
    ...data
  }: Parameters<FirestoreDomainService['storeIncidentState']>[0]): ReturnType<
    FirestoreDomainService['storeIncidentState']
  > {
    const database = this.firestoreConnector.connectToIncidentStore({
      customerId,
      tenantId,
    });
    const id = `${rng()}${Date.now()}`;
    storeIncidentStoreRetryHandler(
      id,
      async () => {
        await new Promise<void>(async (resolve, reject) => {
          await setDoc(doc(database.collection, id), JSON.parse(JSON.stringify(data)));
          resolve();
        });
      },
      { isCheckOnline: true }
    );
    return `https://console.firebase.google.com/project/${staticConfig.projectId}/database/firestore/data~2F${staticConfig.env}~2F${tenantId}~2Fcustomers~2F${customerId}~2Fcustomer_incident_state~2F${id}?hl=ja`;
  }

  public async updateTokenIfNecessary({
    auth,
  }: Parameters<FirestoreDomainService['updateTokenIfNecessary']>[0]): ReturnType<
    FirestoreDomainService['updateTokenIfNecessary']
  > {
    return this.firebaseAuthRepository.checkUpdate({ auth });
  }

  public async updateTyping({
    customerId,
    tenantId,
    projectId,
    isTyping,
    text,
  }: {
    customerId: string;
    tenantId: string;
    projectId: string;
    isTyping: boolean;
    text: string;
  }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const database = this.firestoreConnector.connectToCustomerTyping({
        customerId,
        tenantId,
        projectId,
      });
      if (isTyping) {
        await setDoc(doc(database.collection, 'customerTyping'), {
          timestamp: new Date(),
          text,
        }).catch(reject);
      } else {
        await deleteDoc(doc(database.collection, 'customerTyping')).catch(reject);
      }
      resolve();
    });
  }

  public async getWelcomeMessageOnClosed({
    state,
    onAddMessage,
    onWelcomeMessageTransferRequired,
  }: {
    state: ReduxState;
  } & Pick<
    Parameters<FirestoreDomainService['initializeOnce']>[0],
    'onAddMessage' | 'onWelcomeMessageTransferRequired'
  >): Promise<void> {
    const isConversationClosed = await this.isConversationClosed();
    if (isConversationClosed) {
      const welcomeMessage = this.converter(
        required(state.env.welcomeMessage) as FirestoreSchemaType
      );

      onAddMessage(welcomeMessage.map(w => uniquifyWelcomeMessageId(w, Date.now())));
      onWelcomeMessageTransferRequired();
    }
  }

  private getFirestoreDatabase({
    state,
    auth,
  }: {
    state: ReduxState;
    auth: Auth;
  }): FirestoreDatabaseHolder {
    if (this.database) {
      return this.database;
    }
    const database = this.firestoreConnector.connect({
      tenantId: state.env.tenantId,
      customerId: auth.claims.aim_user_id,
      projectId: state.env.projectId,
    });
    this.database = database;
    return database;
  }

  private async isConversationClosed(): Promise<boolean> {
    if (!this.database) {
      return false;
    }
    const firestoreQuery = query(this.database.collection, orderBy('at', 'desc'), limit(1));
    const maybeCloseEventSnapshot = await getDocs(firestoreQuery);

    return (
      !!maybeCloseEventSnapshot.docs.length &&
      maybeCloseEventSnapshot.docs[0].data().type === 'closed'
    );
  }

  private async isOperatorChatStarted(): Promise<boolean> {
    if (!this.database) {
      return false;
    }

    const firestoreQuery = query(
      this.database.collection,
      orderBy('at', 'desc'),
      where('type', 'in', ['assigned', 'unassigned', 'closed'])
    );

    const events = await getDocs(firestoreQuery);

    return !!events.docs.length && events.docs[0].data().type === 'assigned';
  }

  private async subscribeOperatorTyping({
    customerId,
    tenantId,
    projectId,
    onUpdate,
  }: {
    customerId: string;
    tenantId: string;
    projectId: string;
    onUpdate(a: { isTyping: boolean }): void;
  }): Promise<void> {
    const database = this.firestoreConnector.connectToOperatorTyping({
      customerId,
      tenantId,
      projectId,
    });

    this.typingDisposer = onSnapshot(doc(database.collection, 'operatorTyping'), snapshot => {
      onUpdate({ isTyping: snapshot.exists() });
    });
  }

  private unsubscribeOperatorTyping(): void {
    this.typingDisposer && this.typingDisposer();
  }
}
