/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { SerializedStyles, css } from '@emotion/react';
import { motion, AnimatePresence } from 'framer-motion';
import { regularTextStyle, FONT_SIZE_MEDIUM } from '@s/components/atom/Text';
import { Loading } from '@s/components/atom/Loading';
import { chooseTextColor } from '@s/chooseColor';
import { keyMirror } from '@s/keyMirror';
import { compareOnlyProperties } from '@s/compareOnlyProperties';

export const buttonType = keyMirror([
  'create',
  'update',
  'save',
  'delete',
  'normal',
  'quickReplies',
] as const);
// https://coolors.co/cbcbdd-fe8a83-878ab3-ffd177-fffffc
const TYPE_CONFIG: {
  [P in keyof typeof buttonType]: {
    background: string;
    color: string;
    border?: string;
  };
} = {
  create: {
    background: '#CBCBDD',
    color: chooseTextColor('#CBCBDD'),
  },
  update: {
    background: '#FFD177',
    color: chooseTextColor('#FFD177'),
  },
  save: {
    background: '#00CCFF',
    color: chooseTextColor('#00CCFF'),
  },
  delete: {
    background: '#FE8A83',
    color: chooseTextColor('#FE8A83'),
  },
  normal: {
    background: '#F2F2F3',
    color: chooseTextColor('#F2F2F3'),
  },
  quickReplies: {
    background: '#FFFFFF',
    color: '#0099FF',
    border: '1px solid #DDD',
  },
};

export interface ButtonProps {
  iconSvg?: React.ReactElement;
  loading?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  label: string;
  type: keyof typeof buttonType | { color: string; background: string };
  behaviorType?: 'button' | 'submit' | 'reset';
  className?: string;
  additionalCss?: SerializedStyles | string;
  // eslint-disable-next-line  @typescript-eslint/no-unnecessary-type-arguments
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
}

const FOCUS_SHADOW = `box-shadow: 0px 0px 2px rgba(0, 0, 255, 0.7);`;

const buttonPseudoStyle = (p: { disabled: boolean }) => `
  &:before {
    transition: all 0.2s;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: ${p.disabled ? 'scale3d(1, 1, 1)' : 'scale3d(0, 0, 1)'};
    transform-origin: center center;
    background: ${p.disabled ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.1)'};
    display: block;
    top: 0px;
    left: 0px;
    opacity: ${p.disabled ? 1 : 0};
  }
`;

const buttonHoverStyle = (p: { disabled: boolean }) => css`
  @media (hover: hover) {
    &:hover {
      outline: initial;
      &:before {
        transform: ${p.disabled ? 'none' : 'scale3d(1, 1, 1)'};
        opacity: 1;
      }
    }
  }
`;
const ButtonContainerElement = styled(motion.button)<{
  disabled: boolean;
  cssValue: {
    background: string;
    color: string;
    border: string;
    hover: string;
  };
  isLoading: boolean;
  additionalCss?: SerializedStyles | string;
}>`
  position: relative;
  outline: none;
  box-sizing: border-box;
  border: 0;
  display: inline-block;
  padding: 5px 15px;
  background: ${props => props.cssValue.background};
  color: ${props => props.cssValue.color};
  -webkit-text-fill-color: ${props => props.cssValue.color};
  border: ${props => props.cssValue.border || '0'};
  position: relative;
  overflow: hidden;
  cursor: ${props => (!props.isLoading ? 'pointer' : 'auto')};
  border-radius: 5px;
  height: 35px;
  line-height: ${FONT_SIZE_MEDIUM};
  ${p => p.additionalCss ?? ''};
  &:active,
  &:focus {
    ${FOCUS_SHADOW};
  }
  ${p => (!p.isLoading ? `${buttonPseudoStyle(p)};${buttonHoverStyle(p).styles}` : '')};
`;

const ButtonInnerContainerElement = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTextContainer = styled.span`
  display: inline-block;
  ${regularTextStyle};
`;

export const Button = compareOnlyProperties(
  forwardRef(
    (
      {
        iconSvg,
        label,
        type,
        loading,
        disabled = false,
        tabIndex = 0,
        className = '',
        behaviorType = 'button',
        additionalCss = '',
        onClick = () => {},
      }: ButtonProps,
      ref: React.Ref<HTMLElement | null>
    ) => {
      return (
        <ButtonContainerElement
          disabled={disabled}
          ref={ref as any}
          type={behaviorType}
          isLoading={!!loading}
          cssValue={typeof type === 'string' ? TYPE_CONFIG[type] : (type as any)}
          whileHover={
            !loading && !disabled
              ? {
                  scale: 0.99,
                }
              : {}
          }
          whileTap={!loading && !disabled ? { scale: 0.9 } : {}}
          transition={{ type: 'spring' }}
          tabIndex={disabled ? -1 : tabIndex}
          aira-disabled={disabled}
          aria-label={label}
          aria-busy={loading}
          className={className}
          additionalCss={additionalCss}
          onClick={onClick}
        >
          <AnimatePresence>
            {loading ? <Loading scale={0.2} isBlurEnabled={false} /> : null}
          </AnimatePresence>
          <ButtonInnerContainerElement
            css={{ visibility: loading ? 'hidden' : 'visible' }}
            aria-hidden={true}
          >
            {iconSvg ? (
              <span
                css={{
                  width: 15,
                  height: 15,
                  marginRight: label !== '' ? 10 : 0,
                  display: 'inline-block',
                }}
              >
                {iconSvg}
              </span>
            ) : null}
            <ButtonTextContainer
              css={{ whiteSpace: type === 'quickReplies' ? 'pre-line' : 'nowrap' }}
            >
              {label}
            </ButtonTextContainer>
          </ButtonInnerContainerElement>
        </ButtonContainerElement>
      );
    }
  )
);
