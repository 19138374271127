/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { useContext, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { WIDGET_WIDTH, WIDGET_HEADER_HEIGHT } from '../atom/WidgetConstant';
import { scrollbarStyle } from '../atom/ScrollbarStyle';
import { AnimatePresence, motion } from 'framer-motion';
import { Loading } from '@s/components/atom/Loading';
import { WidgetMessageContainer } from '../molecule/WidgetMessageContainer';
import { BotIcon } from '../atom/BotIcon';
import { WidgetPlainTextMessage } from '../molecule/WidgetPlainTextMessage';
import { WidgetQuickRepliesMessage } from '../molecule/WidgetQuickRepliesMessage';
import { WidgetTyping } from '../atom/WidgetTyping';
import { WidgetImageMessage } from '../molecule/WidgetImageMessage';
import { UserIcon } from '../atom/UserIcon';
import { WidgetContext } from '../atom/WidgetContext';
import * as smoothscroll from 'smoothscroll-polyfill';
import { PASSIVE } from '@s/detectDomFeature';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { useScrollFix } from '@s/platform/genericMobile/useScrollFix';
import { useiOSOverScrollFreezeFix } from '@s/platform/ios/useiOSOverScrollFreezeFix';
import { useAndroidKeyboardScrollFix } from '@s/platform/android/useAndroidKeyboardScrollFix';
import { useAnimationFrameFixer } from '@s/dom/useAnimationFrameFixer';
import { useOrientationChange } from '@s/platform/genericMobile/useOrientationChange';
import { useKeyboardAppearanceWaiter } from '@s/platform/generic/useKeyboardAppearanceWaiter';
import { useRefState } from '@s/reactHooks';
import { MessageSender, QuickReplyPostbackPayload } from '@s/domain/entity/MessageFormat';
import { DisplayableMessageFormat } from '../atom/WidgetMessageConfig';
import { Environment } from '@s/platform/Environment';
import { MediaCache } from '@s/domain/entity/MediaCache';
import { DayjsWrapper } from '@s/util/DayjsWrapper';
import { AudioStatus } from '@s/components/molecule/AuidoComponent';
import { Flex, Box } from '../atom/Box';

const WidgetTalkAreaContainerElement = styled.section`
  padding: 35px 10px 10px 10px;
  height: 100%;
  flex-shrink: 1;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  ${scrollbarStyle};
  position: relative;
`;

const WidgetTalkAreaOrderListElement = styled.ol`
  position: relative;
  padding: 0px;
  > li {
    padding: 0;
    margin: 0;
    list-style: none;
    &:not(:last-child) {
      margin-bottom: 55px;
    }
  }
`;

const widgetTalkAreaFocusStyle = css`
  &:focus {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 255, 0.6);
    .aim__talkarea__widget-message {
      box-shadow: 0px 0px 4px rgba(0, 0, 255, 0.6) !important;
    }
  }
`;
const WidgetTalkAreaMessageContainerElement = styled(motion.li)<{
  isSupportedMobileBrowser: boolean;
}>`
  outline: none;
  ${p => (!p.isSupportedMobileBrowser ? widgetTalkAreaFocusStyle : '')};
`;

const DateContainerElement = styled.article`
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
`;

const WidgetTalkAreaMessageElement = ({
  children,
  sender,
  label,
}: {
  children: React.ReactNode;
  sender: MessageSender;
  label: string;
}) => {
  const { environment } = useContext(WidgetEnvContext);
  return (
    <WidgetTalkAreaMessageContainerElement
      isSupportedMobileBrowser={environment.isSupportedMobileBrowser}
      initial={{
        translateX: sender === 'Customer' ? WIDGET_WIDTH : -WIDGET_WIDTH,
        opacity: 0,
      }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{
        translateX: sender === 'Customer' ? WIDGET_WIDTH : -WIDGET_WIDTH,
        opacity: 0,
      }}
      transition={{ type: 'tween', duration: 0.2, ease: 'anticipate' }}
      tabIndex={0}
      aria-label={label}
    >
      {children}
    </WidgetTalkAreaMessageContainerElement>
  );
};

export type WidgetTalkAreaProps = {
  loading: boolean;
  messages: ReadonlyDeep<DisplayableMessageFormat[]>;
  mediaCache: MediaCache;
  isOperatorMode?: boolean;
  isDisplayTyping: boolean;
  onSendMessage?(msg: string, uuid?: string): void;
  onSendQuickRepliesPostPack?(msg: string, payload: string, uuid?: string): void;
  onDeleteMessage?(uuid: string): void;
  onScroll?(messages: DisplayableMessageFormat[]): void;
  onCacheMedia?(url: string): void;
};

class WidgetScrollDataCache {
  public clientHeight = 0;
  public height = 0;
  public isAutoScrolling = false;
  public isScrollToBottom = true;
  public isUserScrollingOnMobileDevice = false;
  public scrollHeight = 0;
  public top = 0;
}

const useWidgetScrolling = ({
  messages,
  environment,
  rootElementRef,
  isInputFocused,
  isShowInput,
  onScroll,
}: {
  messages: ReadonlyDeep<DisplayableMessageFormat[]>;
  environment: Environment;
  rootElementRef: React.RefObject<HTMLElement | null>;
  isInputFocused: boolean;
  isShowInput: boolean;
  onScroll(messages: ReadonlyDeep<DisplayableMessageFormat[]>): void;
}) => {
  const [scrollDimension, setScrollDimension] = useRefState(new WidgetScrollDataCache());
  const currentPastMessageTime = useRef(0);
  const messagesRef = useRef(messages);
  const [prevMessageLength, setPrevMessageLength] = useRefState(0);
  const shouldStopInterScrollingProcess = useRef(false);
  messagesRef.current = messages;
  const keyboardAppearanceWaiter = useKeyboardAppearanceWaiter({
    isInputFocused: isInputFocused || isShowInput,
    containerElementRef: rootElementRef,
  });

  const scrollCallback = useScrollFix({
    environment,
    scrollCallback: useAnimationFrameFixer(() => {
      if (!rootElementRef.current || shouldStopInterScrollingProcess.current) {
        return;
      }
      if (!scrollDimension.current.isAutoScrolling) {
        scrollDimension.current.top = rootElementRef.current.scrollTop;
        scrollDimension.current.isScrollToBottom = isAutoScrollable();
        if (rootElementRef.current.scrollTop <= 10) {
          onScroll(messagesRef.current);
        }
      } else if (
        environment.isSupportedMobileBrowser &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        scrollDimension.current.isAutoScrolling &&
        isAutoScrollable()
      ) {
        scrollDimension.current.isAutoScrolling = false;
      }
    }),
    containerElementRef: rootElementRef,
  });
  const scrollCallbackRef = useRef(scrollCallback);
  scrollCallbackRef.current = scrollCallback;

  useEffect(() => {
    const cacheHeight = () => {
      if (rootElementRef.current) {
        setScrollDimension({
          ...scrollDimension.current,
          scrollHeight: rootElementRef.current.scrollHeight,
          clientHeight: rootElementRef.current.clientHeight,
        });
        shouldStopInterScrollingProcess.current = false;
        scrollCallbackRef.current({} as any);
      }
    };
    if (messages.length && rootElementRef.current) {
      shouldStopInterScrollingProcess.current = true;
      keyboardAppearanceWaiter(cacheHeight);
    }
  }, [messages, isInputFocused, isShowInput]);

  const scrollToBottom = ({ behavior }: { behavior?: 'smooth' | 'auto' } = {}) => {
    if (!rootElementRef.current) {
      return;
    }
    const scrollHeight = rootElementRef.current.scrollHeight;
    const scrollTo = window.scrollTo;
    smoothscroll.polyfill();
    // 新データ追加時
    scrollDimension.current.top = scrollHeight - rootElementRef.current.clientHeight;
    scrollDimension.current.isAutoScrolling = true;
    rootElementRef.current.scrollTo({
      top: scrollDimension.current.top,
      behavior: behavior ?? currentPastMessageTime.current ? 'smooth' : 'auto',
    });
    scrollDimension.current.height = scrollHeight;
    window.scrollTo = scrollTo;

    if (!currentPastMessageTime.current) {
      const update = () => {
        setTimeout(() => {
          if (!rootElementRef.current) {
            return;
          }
          const next = rootElementRef.current.scrollHeight - rootElementRef.current.clientHeight;
          if (rootElementRef.current.scrollTop < next - 20) {
            rootElementRef.current.scrollTo({
              top: next,
            });
            update();
          }
        }, 400);
      };
      update();
    }
  };

  const interScrollingProcess = useAnimationFrameFixer(() => {
    if (!rootElementRef.current) {
      return;
    }

    if (messages.length === 0) {
      scrollDimension.current.top = 0;
      return;
    }

    // 更新処理
    if (messages[0].at < currentPastMessageTime.current) {
      const scrollHeight = rootElementRef.current.scrollHeight;
      // 過去ログ取得時
      const nextScrollTop = scrollHeight - scrollDimension.current.height;
      rootElementRef.current.scrollTop = nextScrollTop;
      scrollDimension.current.top = nextScrollTop;
      scrollDimension.current.height = scrollHeight;
    } else if (
      scrollDimension.current.isScrollToBottom &&
      !scrollDimension.current.isUserScrollingOnMobileDevice
    ) {
      scrollToBottom();
    }

    if (messages.length > 0) {
      currentPastMessageTime.current = messages[0].at;
    }
  });

  useEffect(() => {
    setTimeout(interScrollingProcess, 300);
    if (messages.length > prevMessageLength.current && rootElementRef.current) {
      setScrollDimension({
        ...scrollDimension.current,
        scrollHeight: rootElementRef.current.scrollHeight,
        clientHeight: rootElementRef.current.clientHeight,
      });
      setPrevMessageLength(messages.length);
    }
  }, [
    messages,
    rootElementRef.current,
    rootElementRef.current?.scrollHeight,
    scrollDimension.current,
    isShowInput,
  ]);

  useAndroidKeyboardScrollFix({
    environment,
    isInputFocused: isShowInput,
    onTick() {
      if (
        !scrollDimension.current.isUserScrollingOnMobileDevice &&
        scrollDimension.current.isScrollToBottom
      ) {
        scrollToBottom({ behavior: 'auto' });
      }
    },
  });

  const isAutoScrollable = (): boolean => {
    if (!rootElementRef.current) {
      return true;
    }
    return (
      rootElementRef.current.scrollTop >
      scrollDimension.current.scrollHeight - scrollDimension.current.clientHeight - 20
    );
  };

  useOrientationChange({
    environment,
    onChange() {
      if (scrollDimension.current.isScrollToBottom) {
        scrollToBottom();
      }
    },
  });

  useEffect(() => {
    if (!rootElementRef.current) {
      return;
    }
    const touchstartHandler = () => {
      shouldStopInterScrollingProcess.current = false;
      scrollDimension.current.isUserScrollingOnMobileDevice = true;
    };
    const touchmoveHandler = () => {
      scrollDimension.current.isAutoScrolling = false;
    };
    const touchendHandler = () => {
      scrollDimension.current.isUserScrollingOnMobileDevice = false;
    };
    const wheelHandler = () => {
      scrollDimension.current.isAutoScrolling = false;
      shouldStopInterScrollingProcess.current = false;
    };
    rootElementRef.current.addEventListener('touchstart', touchstartHandler, PASSIVE);
    rootElementRef.current.addEventListener('touchmove', touchmoveHandler, PASSIVE);
    rootElementRef.current.addEventListener('touchend', touchendHandler, PASSIVE);
    rootElementRef.current.addEventListener('wheel', wheelHandler, PASSIVE);

    const scrollHandler = (e: Event) => {
      scrollCallbackRef.current(e);
    };
    rootElementRef.current.addEventListener('scroll', scrollHandler, PASSIVE);
    return () => {
      if (rootElementRef.current) {
        rootElementRef.current.removeEventListener('touchmove', touchmoveHandler);
        rootElementRef.current.removeEventListener('touchstart', touchstartHandler);
        rootElementRef.current.removeEventListener('touchend', touchendHandler);
        rootElementRef.current.removeEventListener('scroll', scrollHandler);
        rootElementRef.current.removeEventListener('wheel', wheelHandler);
      }
    };
  }, []);

  return scroll();
};

const getRelativeTime = (messageTime: Date, now: Date, isOperatorMode: boolean) => {
  if (isOperatorMode) {
    return `(${new DayjsWrapper(messageTime).from(now)})`;
  }
  return '';
};

export const WidgetTalkArea = compareOnlyProperties(
  ({
    loading,
    messages,
    mediaCache,
    theme = 'web',
    isOperatorMode = false,
    isInputFocused,
    isShowInput,
    isDisplayTyping,
    shouldDisplayDate = false,
    audioStatus,
    onDeleteMessage = () => {},
    onSendMessage,
    onSendQuickRepliesPostPack = () => {},
    onScroll = () => {},
    onCacheMedia = () => {},
    customerInput = '',
    onUserInput = () => {},
    onClickAudioSeekButton,
  }: WidgetTalkAreaProps & {
    isInputFocused: boolean;
    isShowInput: boolean;
    theme?: 'instagram' | 'web';
    shouldDisplayDate?: boolean;
    customerInput?: string;
    audioStatus?: AudioStatus;
    onUserInput?(a: { isTyping: boolean; text: string }): void;
    onClickAudioSeekButton?(seekTime: number): void;
  }) => {
    const { environment } = useContext(WidgetEnvContext);
    const { config, isOperatorChatting } = useContext(WidgetContext);
    const [scrollTop, setScrollTop] = useRefState(0);
    const rootRef = useRef<HTMLDivElement>(null);
    const [timerId, setTimerId] = useRefState<number | null>(null);
    const [date, setDate] = useRefState(new Date());
    const renderContents = (
      msg: ReadonlyDeep<DisplayableMessageFormat>,
      mediaCache: MediaCache
    ) => {
      const sender = msg.sender.type;
      switch (msg.type) {
        case 'plainText':
        case 'postback':
          return (
            <WidgetPlainTextMessage
              theme={theme}
              sender={sender}
              text={msg.text !== null ? msg.text : '(data format error)'}
            />
          );
        case 'instagramStoryMention':
          return <WidgetImageMessage src={mediaCache[msg.payload.URL] ?? msg.payload.URL} />;
        case 'image':
          return <WidgetImageMessage src={mediaCache[msg.payload.url] ?? msg.payload.url} />;
        case 'instagramMessageDelete':
          return null;
        case 'instagramStoryReply':
          return (
            <Flex flexDirection="column" alignItems="flex-start">
              <WidgetImageMessage
                src={mediaCache[msg.payload.ReplyStoryURL] ?? msg.payload.ReplyStoryURL}
              />
              <Box margin="10px 0 0 0">
                <WidgetPlainTextMessage theme={theme} sender={sender} text={msg.payload.Text} />
              </Box>
            </Flex>
          );
        default:
          return;
      }
    };

    // メッセージコンテンツ出し分け
    const { error } = useContext(WidgetContext);
    const renderTalkArea = (
      messages: ReadonlyDeep<DisplayableMessageFormat[]>,
      mediaCache: MediaCache,
      isOperatorMode: boolean,
      nowDate: Date
    ) => {
      return messages.flatMap((msg, index) => {
        let isError = false;
        error?.uuid.forEach(id => {
          if (!isError) {
            isError = msg.uuid === id;
          }
        });
        const sender = msg.sender.type;
        const onResendMessage = () => {
          if (onSendMessage) {
            onSendMessage(msg.payload as string, msg.uuid);
          }
        };
        const onResendPostBack = () => {
          const payload: QuickReplyPostbackPayload = msg.payload as any;
          onSendQuickRepliesPostPack(payload.text, payload.payload, msg.uuid);
        };
        const onDelete = () => {
          msg.uuid && onDeleteMessage(msg.uuid);
        };
        const key = msg.uuid;
        const messageContent = renderContents(msg, mediaCache);
        if (!messageContent) {
          return [];
        }
        const message = msg.quickReplies ? (
          <WidgetQuickRepliesMessage
            isOperatorMode={isOperatorMode}
            buttons={msg.quickReplies.map(btn => {
              return {
                type: btn.type,
                label: btn.label,
                payload: btn.payload,
              };
            })}
            onSendQuickRepliesPostPack={(...args) => {
              onUserInput({ isTyping: false, text: '' });
              onSendQuickRepliesPostPack(...args);
            }}
          >
            {messageContent}
          </WidgetQuickRepliesMessage>
        ) : (
          messageContent
        );

        return [
          <WidgetTalkAreaMessageElement key={key} sender={sender} label={''}>
            <WidgetMessageContainer
              key={key}
              isPseudoMessage={msg.id === 'dummy'}
              isSendFailed={isError}
              setsize={5}
              index={index}
              sender={sender}
              theme={theme}
              pictureUrl={
                sender === 'Customer'
                  ? config.customerIconSrc
                  : sender === 'Operator'
                  ? config.operatorIconSrc
                  : sender === 'Bot'
                  ? config.botIconSrc
                  : ''
              }
              seekTime={msg.type === 'plainText' ? msg.seekTime : undefined}
              pictureSvg={
                sender === 'Customer' ? <UserIcon /> : sender === 'Bot' ? <BotIcon /> : undefined
              }
              isOperatorMode={isOperatorMode}
              audioStatus={audioStatus}
              onDelete={onDelete}
              onRetry={msg.type === 'postback' ? onResendPostBack : onResendMessage}
              onClickAudioSeekButton={seekTime => {
                onClickAudioSeekButton && onClickAudioSeekButton(seekTime);
              }}
            >
              {message}
            </WidgetMessageContainer>
            <DateContainerElement
              css={{
                flexDirection:
                  sender === 'Customer'
                    ? isOperatorMode
                      ? 'row'
                      : 'row-reverse'
                    : isOperatorMode
                    ? 'row-reverse'
                    : 'row',
              }}
            >
              {shouldDisplayDate &&
                !msg.isWelcomeByBot &&
                `${DayjsWrapper.formatDateToDisplayStr(
                  new Date(msg.viewAt),
                  'seconds'
                )}${getRelativeTime(new Date(msg.viewAt), nowDate, isOperatorMode)}`}
            </DateContainerElement>
          </WidgetTalkAreaMessageElement>,
        ];
      });
    };

    useWidgetScrolling({
      rootElementRef: rootRef,
      environment,
      messages,
      isInputFocused,
      isShowInput,
      onScroll,
    });

    useiOSOverScrollFreezeFix({
      containerElementRef: rootRef,
      environment,
    });

    const cancelAnimationFrameId = useRef(0);
    const scrollHandler = () => {
      cancelAnimationFrame(cancelAnimationFrameId.current);
      cancelAnimationFrameId.current = requestAnimationFrame(() => {
        rootRef.current && setScrollTop(rootRef.current.scrollTop);
      });
    };

    useEffect(() => {
      messages.map(msg => {
        if (
          msg.type === 'image' &&
          msg.sender.type === 'Customer' &&
          mediaCache[msg.payload.url] === undefined
        ) {
          onCacheMedia(msg.payload.url);
        }
      });
      setDate(new Date());
    }, [messages]);

    useEffect(() => {
      if (timerId.current) {
        window.clearInterval(timerId.current);
        setTimerId(null);
      }
      if (isOperatorMode) {
        const tid = window.setInterval(() => {
          setDate(new Date());
        }, 60000);
        setTimerId(tid);
      }
      return () => {
        if (timerId.current) {
          window.clearInterval(timerId.current);
          setTimerId(null);
        }
      };
    }, []);
    return (
      <>
        <WidgetTalkAreaContainerElement
          role="main"
          ref={rootRef}
          onScroll={() => {
            scrollHandler();
          }}
        >
          <AnimatePresence>
            {loading ? (
              <div
                css={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: scrollTop.current,
                }}
              >
                <Loading scale={0.5} isModalEnabled={true} />
              </div>
            ) : null}
          </AnimatePresence>
          <WidgetTalkAreaOrderListElement role="feed" aria-busy={loading}>
            {renderTalkArea(messages, mediaCache, isOperatorMode, date.current)}
          </WidgetTalkAreaOrderListElement>
        </WidgetTalkAreaContainerElement>
        {rootRef.current && isDisplayTyping ? (
          <WidgetTyping
            isOperatorMode={isOperatorMode}
            isOperatorChatting={isOperatorChatting}
            customerInput={customerInput}
            y={rootRef.current.clientHeight - 10 + (!isOperatorMode ? WIDGET_HEADER_HEIGHT : 0)}
          />
        ) : null}
      </>
    );
  },
  'WidgetTalkArea'
);
