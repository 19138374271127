/**
 * @fileoverview
 * @author Taketoshi Aono
 */

import React, { HTMLAttributes, forwardRef, useContext, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from '@emotion/styled';
import { WidgetAvatar } from '../atom/WidgetAvatar';
import { WIDGET_WIDTH } from '../atom/WidgetConstant';
import { RefreshIcon } from '../atom/RefreshIcon';
import { CrossbarIcon } from '../atom/CrossbarIcon';
import { smallTextStyle } from '../atom/Text';
import { compareOnlyProperties } from '@s/compareOnlyProperties';
import { WidgetEnvContext } from '../atom/WidgetEnvContext';
import { useRefState } from '@s/reactHooks';
import { MessageSender } from '@s/domain/entity/MessageFormat';
import { WidgetType } from '../atom/WidgetType';
import { AudioSeekButton } from '@s/components/molecule/AudioSeekButton';
import { AudioStatus } from '@s/components/molecule/AuidoComponent';
import { EmptyUserIcon } from '@s/components/atom/EmptyUserIcon';

const WidgetMessageContainerElement = styled.article`
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  align-items: flex-start;
`;

const IconContainerElement = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
  border: 0;
  position: relative;
  transition: box-shadow 0.3s;
  cursor: pointer;
  &:after {
    transition: all 0.3s;
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1;
    transform: scale(0);
    border-radius: 20px;
  }
  &:hover {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    :after {
      transform: scale(1);
    }
  }
  > svg {
    width: 15px;
    height: 15px;
  }
`;
const ResendIcon = React.memo(
  (a: HTMLAttributes<HTMLButtonElement>) => (
    <IconContainerElement
      type="button"
      css={{ background: '#0066FF' }}
      tabIndex={0}
      aria-label="再送信"
      whileHover={{ scale: 0.95 }}
      whileTap={{ scale: 0.8 }}
      transition={{ type: 'spring', mass: 0.5 }}
      onClick={a.onClick}
    >
      <RefreshIcon {...a} color="#FFF" />
    </IconContainerElement>
  ),
  () => false
);

const DeleteIcon = React.memo(
  (a: HTMLAttributes<HTMLButtonElement>) => (
    <IconContainerElement
      type="button"
      css={{ background: '#FF6600' }}
      tabIndex={0}
      aria-label="削除"
      whileHover={{ scale: 0.95 }}
      whileTap={{ scale: 0.8 }}
      transition={{ type: 'spring', mass: 0.5 }}
      onClick={a.onClick}
    >
      <CrossbarIcon {...a} color="#FFF" />
    </IconContainerElement>
  ),
  () => false
);

const SendFailedIconsContainerElement = styled(motion.div)`
  right: 0px;
  top: -30px;
  position: absolute;
  display: flex;
  align-items: center;
`;

const FailedMessageText = styled.div`
  ${smallTextStyle};
`;

const RetryMenu = compareOnlyProperties(
  ({
    onRetry = () => {},
    onDelete = () => {},
  }: Pick<WidgetMessageContainerProps, 'onRetry' | 'onDelete'>) => {
    return (
      <SendFailedIconsContainerElement
        initial={{ translateX: 10, opacity: 0 }}
        animate={{ translateX: 0, opacity: 1 }}
        exit={{ translateX: 10, opacity: 0 }}
        transition={{ type: 'spring', mass: 0.4 }}
      >
        <FailedMessageText css={{ marginRight: 5 }}>送信に失敗しました</FailedMessageText>
        <div css={{ marginRight: 3 }}>
          <ResendIcon
            onClick={() => {
              onRetry();
            }}
          />
        </div>
        <DeleteIcon
          onClick={() => {
            onDelete();
          }}
        />
      </SendFailedIconsContainerElement>
    );
  },
  'RetryMenu'
);

export interface WidgetMessageContainerProps {
  pictureUrl?: string;
  pictureSvg?: React.ReactElement;
  isSendFailed?: boolean;
  seekTime?: number;
  theme?: 'instagram' | 'web';
  children: React.ReactNode;
  sender: MessageSender;
  index: number;
  setsize: number;
  isOperatorMode: boolean;
  audioStatus?: AudioStatus;
  onRetry?(): void;
  onDelete?(): void;
  onClickAudioSeekButton?(seekTime: number): void;
}

export const WidgetMessageContainer = compareOnlyProperties(
  forwardRef(
    (
      {
        children,
        sender,
        isSendFailed: isSendFailedProp,
        pictureSvg,
        pictureUrl,
        seekTime,
        index,
        setsize,
        isPseudoMessage,
        isOperatorMode,
        theme,
        audioStatus,
        onRetry = () => {},
        onDelete = () => {},
        onClickAudioSeekButton,
      }: WidgetMessageContainerProps & { isPseudoMessage: boolean },
      ref: React.Ref<any>
    ) => {
      const { environment, type } = useContext(WidgetEnvContext);
      const [isSendFailed, setSendFailed] = useRefState(isSendFailedProp);

      useEffect(() => {
        setTimeout(() => {
          if (isPseudoMessage) {
            setSendFailed(true);
          }
        }, 15000);
      }, []);

      useEffect(() => {
        setSendFailed(isSendFailedProp);
      }, [isSendFailedProp]);

      return (
        <WidgetMessageContainerElement
          css={{
            flexDirection:
              sender === 'Customer'
                ? isOperatorMode
                  ? 'row'
                  : 'row-reverse'
                : isOperatorMode
                ? 'row-reverse'
                : 'row',
            alignItems: theme === 'web' ? 'flex-start' : 'flex-end',
          }}
          aria-posinset={index}
          aria-setsize={setsize}
          ref={ref}
        >
          <div
            css={{
              ...(sender === 'Customer'
                ? isOperatorMode
                  ? { marginRight: 10 }
                  : { marginLeft: pictureUrl ? 10 : 0 }
                : isOperatorMode
                ? { marginLeft: pictureUrl ? 10 : 0 }
                : { marginRight: 10 }),
              marginTop: theme === 'web' ? -25 : 0,
            }}
          >
            {pictureUrl ? (
              <WidgetAvatar pictureUrl={pictureUrl} sender={sender} />
            ) : theme === 'web' &&
              pictureSvg &&
              ((sender !== 'Customer' && !isOperatorMode) ||
                (sender === 'Customer' && isOperatorMode)) ? (
              pictureSvg
            ) : theme === 'instagram' &&
              ((sender !== 'Customer' && !isOperatorMode) ||
                (sender === 'Customer' && isOperatorMode)) ? (
              <EmptyUserIcon shouldReverseColor={true} size={20} />
            ) : null}
          </div>
          <div
            className="aim__talkarea__widget-message-container"
            css={{
              maxWidth: environment.isSupportedMobileBrowser
                ? '70vw'
                : type === WidgetType.FLOATING
                ? WIDGET_WIDTH - 95
                : 'calc(100% - 10px)',
            }}
          >
            {children}
          </div>
          <AnimatePresence>
            {isSendFailed.current ? <RetryMenu onDelete={onDelete} onRetry={onRetry} /> : null}
          </AnimatePresence>
          {seekTime !== undefined && (
            <AudioSeekButton
              seekTime={seekTime}
              status={audioStatus || 'initial'}
              onClick={seekTime => onClickAudioSeekButton && onClickAudioSeekButton(seekTime)}
            />
          )}
        </WidgetMessageContainerElement>
      );
    }
  ),
  'WidgetMessageContainer'
);
