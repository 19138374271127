/**
 * @fileOverview
 * @name FirestoreConnection.ts
 * @author Taketoshi Aono
 * @license
 */

import { FirestoreDatabaseHolder, FirestoreDatabase } from './FirestoreDatabase';
import { FirebaseHandleable } from './FirebaseHandler';
import { staticConfig } from '@w/config';
import { collection, getFirestore } from 'firebase/firestore';

export interface FirestoreConnectable {
  connect({
    tenantId,
    customerId,
    projectId,
  }: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): FirestoreDatabaseHolder;

  connectToIncidentStore({
    tenantId,
    customerId,
  }: {
    tenantId: string;
    customerId: string;
  }): FirestoreDatabaseHolder;

  connectToOperatorTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): FirestoreDatabaseHolder;
  connectToCustomerTyping(a: {
    tenantId: string;
    customerId: string;
    projectId: string;
  }): FirestoreDatabaseHolder;
}

export class FirestoreConnector {
  public constructor(private readonly firebaseHandler: FirebaseHandleable) {}

  public connect({
    customerId,
    projectId,
    tenantId,
  }: Parameters<FirestoreConnectable['connect']>[0]): ReturnType<FirestoreConnectable['connect']> {
    const db = getFirestore(this.firebaseHandler.app);

    const firestoreCollection = collection(
      db,
      staticConfig.env,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationEvents'
    );

    return new FirestoreDatabase(firestoreCollection);
  }

  public connectToCustomerTyping({
    customerId,
    projectId,
    tenantId,
  }: Parameters<FirestoreConnectable['connectToCustomerTyping']>[0]): FirestoreDatabaseHolder {
    const db = getFirestore(this.firebaseHandler.app);

    const firestoreCollection = collection(
      db,
      staticConfig.env,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationStatusEvents',
      'customerTyping'
    );

    return new FirestoreDatabase(firestoreCollection);
  }

  public connectToIncidentStore({
    customerId,
    tenantId,
  }: Parameters<FirestoreConnectable['connectToIncidentStore']>[0]): ReturnType<
    FirestoreConnectable['connectToIncidentStore']
  > {
    const db = getFirestore(this.firebaseHandler.app);
    const firestoreCollection = collection(
      db,
      staticConfig.env,
      tenantId,
      'customers',
      customerId,
      'customer_incident_state'
    );

    return new FirestoreDatabase(firestoreCollection);
  }

  public connectToOperatorTyping({
    customerId,
    projectId,
    tenantId,
  }: Parameters<FirestoreConnectable['connectToOperatorTyping']>[0]): FirestoreDatabaseHolder {
    const db = getFirestore(this.firebaseHandler.app);
    const firestoreCollection = collection(
      db,
      staticConfig.env,
      tenantId,
      'customers',
      customerId,
      'projects',
      projectId,
      'conversationStatusEvents',
      'operatorTyping'
    );

    return new FirestoreDatabase(firestoreCollection);
  }
}
